import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TableOfContents = makeShortcode("TableOfContents");
const GridList = makeShortcode("GridList");
const GridListItem = makeShortcode("GridListItem");
const StatePageBrandExamplesSection = makeShortcode("StatePageBrandExamplesSection");
const ImageWithCaption = makeShortcode("ImageWithCaption");
const FeedbackThumbs = makeShortcode("FeedbackThumbs");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <TableOfContents title="On this page" mdxType="TableOfContents">
      <div className="table-of-contents">
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#lunas-states-principles"
            }}>{`Luna's states principles`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#styles"
            }}>{`Styles`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#types-of-states"
            }}>{`Types of states`}</a></li>
        </ul>
      </div>
    </TableOfContents>
    <h2 {...{
      "id": "lunas-states-principles",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#lunas-states-principles",
        "aria-label": "lunas states principles permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Luna's states principles`}</h2>
    <GridList columns="3" mdxType="GridList">
  <GridListItem title="Clear" titleElement="h3" border mdxType="GridListItem">
    States must have a clear purpose to distinguish them from one another as
    well as the surrounding user interface.
  </GridListItem>
  <GridListItem title="Consistent" titleElement="h3" border mdxType="GridListItem">
    States should be applied consistently across elements to help with usability
    and create recognisable patterns.
  </GridListItem>
    </GridList>
    <hr></hr>
    <StatePageBrandExamplesSection mdxType="StatePageBrandExamplesSection" />
    <hr></hr>
    <h2 {...{
      "id": "styles",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#styles",
        "aria-label": "styles permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Styles`}</h2>
    <p>{`The different styles of states we have in Luna are:`}</p>
    <TableOfContents mdxType="TableOfContents">
      <ul>
  <li>
    <a className="ln-c-link" href="#inactiveactive-state">
      Inactive/active state
    </a>
  </li>
  <li>
    <a className="ln-c-link" href="#hovered-state">
      Hovered state
    </a>
  </li>
  <li>
    <a className="ln-c-link" href="#disabled-state">
      Disabled state
    </a>
  </li>
  <li>
    <a className="ln-c-link" href="#error-state">
      Error state
    </a>
  </li>
      </ul>
    </TableOfContents>
    <h3 id="inactiveactive-state">Inactive/active state</h3>
    <p>{`An element goes from an inactive to active state when the user chooses to select it, or turn it on. It turns back to inactive when it is deselected or turned off.`}</p>
    <GridList columns="3" title="Styling" mdxType="GridList">
  <GridListItem removePadding="true" mdxType="GridListItem">
    <p>
      Unselected components are the default, and they use our monochrome colour
      palette.
    </p>
    <p>Selected components use brand colours to recognise the state change.</p>
  </GridListItem>
  <GridListItem removePadding="true" mdxType="GridListItem">
    <ImageWithCaption imageName="sainsburys-inactive" caption="Sainsbury’s inactive example" mdxType="ImageWithCaption" />
  </GridListItem>
  <GridListItem removePadding="true" mdxType="GridListItem">
    <ImageWithCaption imageName="sainsburys-active" caption="Sainsbury’s active example" mdxType="ImageWithCaption" />
  </GridListItem>
    </GridList>
    <h3 id="hovered-state">Hovered state</h3>
    <p>{`A hover state is used when the pointer is over an interactive element, but only if it hasn’t been clicked or dragged. It’s most often used to show that components are clickable as the pointer moves across them, or to show labels or instructions indicating what a button will do.`}</p>
    <GridList columns="3" title="Styling" mdxType="GridList">
  <GridListItem removePadding="true" mdxType="GridListItem">
    <p>
      Hovered components have <strong>6% opacity</strong>. The opacity is
      usually applied at the parent component level or inactive state.
    </p>
    <p>
      Hovered components have a <strong>2px stroke</strong> as a base. The
      stroke weight is usually applied at the parent component level or active
      state.
    </p>
  </GridListItem>
  <GridListItem removePadding="true" mdxType="GridListItem">
    <ImageWithCaption imageName="hovered-button" caption="Argos 6% opacity hovered state example" mdxType="ImageWithCaption" />
  </GridListItem>
  <GridListItem removePadding="true" mdxType="GridListItem">
    <ImageWithCaption imageName="hovered-checkbox" caption="Argos 2px stroke hovered state example" mdxType="ImageWithCaption" />
  </GridListItem>
    </GridList>
    <h3 id="disabled-state">Disabled state</h3>
    <p>{`A disabled state shows when an action isn’t available to a user.`}</p>
    <GridList columns="3" title="Styling" mdxType="GridList">
  <GridListItem removePadding="true" mdxType="GridListItem">
    <p>
      Disabled components have <strong>35% opacity</strong>. The opacity is
      usually applied to the entire component.
    </p>
    <p>Disabled states can also be communicated with color changes.</p>
  </GridListItem>
  <GridListItem removePadding="true" mdxType="GridListItem">
    <ImageWithCaption imageName="disabled-button" caption="Tu disabled state example" mdxType="ImageWithCaption" />
  </GridListItem>
  <GridListItem removePadding="true" mdxType="GridListItem">
    <ImageWithCaption imageName="disabled-radio" caption="Tu disabled state example" mdxType="ImageWithCaption" />
  </GridListItem>
    </GridList>
    <h3 id="error-state">Error state</h3>
    <p>{`An error state usually appears when an user fails to complete an expected action.`}</p>
    <GridList columns="3" title="Styling" mdxType="GridList">
  <GridListItem removePadding="true" mdxType="GridListItem">
    <p>
      Error states on components have a <strong>2px stroke</strong> as a base.
      The stroke weight is applied at the parent component level or active
      state.
    </p>
    <p>
      Error states can also be shown through color changes, using our semantic
      error base red.
    </p>
  </GridListItem>
  <GridListItem removePadding="true" mdxType="GridListItem">
    <ImageWithCaption imageName="error-form" caption="Nectar error state example" mdxType="ImageWithCaption" />
  </GridListItem>
    </GridList>
    <hr></hr>
    <h2 {...{
      "id": "types-of-states",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#types-of-states",
        "aria-label": "types of states permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Types of states`}</h2>
    <p>{`Explore some examples of Luna’s interactive states across a commonly used component.`}</p>
    <GridList columns="3" mdxType="GridList">
  <GridListItem removePadding="true" mdxType="GridListItem">
    <ImageWithCaption imageName="input-inactive-normal" caption="Inactive - Normal" mdxType="ImageWithCaption" />
  </GridListItem>
  <GridListItem removePadding="true" mdxType="GridListItem">
    <ImageWithCaption imageName="input-inactive-hovered" caption="Inactive - Hovered" mdxType="ImageWithCaption" />
  </GridListItem>
  <GridListItem removePadding="true" mdxType="GridListItem">
    <ImageWithCaption imageName="input-inactive-disabled" caption="Inactive - Disabled" mdxType="ImageWithCaption" />
  </GridListItem>
  <GridListItem removePadding="true" mdxType="GridListItem">
    <ImageWithCaption imageName="input-active-focused" caption="Active - Focused" mdxType="ImageWithCaption" />
  </GridListItem>
  <GridListItem removePadding="true" mdxType="GridListItem">
    <ImageWithCaption imageName="input-active-focused" caption="Active - Focused (Entered Text)" mdxType="ImageWithCaption" />
  </GridListItem>
  <GridListItem removePadding="true" mdxType="GridListItem">
    <ImageWithCaption imageName="input-error" caption="Error" mdxType="ImageWithCaption" />
  </GridListItem>
    </GridList>
    <hr></hr>
    <FeedbackThumbs mdxType="FeedbackThumbs" />
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  We’re on hand to answer any questions you have or help you with your next
  project.
    </GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      